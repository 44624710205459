import { json } from 'd3-fetch';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { transition } from 'd3-transition';
import { timeout } from 'd3-timer';
import fill from './light.js';
import favicon from './favicon.js';

const HALF_PI = Math.PI / 2;
const touchDevice = 'ontouchstart' in document.documentElement;

// Options
let options = {
  width: 600,
  height: 600,
  square: 740, // Margin
  azimuth: -0.25,
  x: -0.008,
  y: 0.055,
};

// Scale
let halfWidth = options.width / 2;
let halfHeight = options.height / 2;
let halfSquare = options.square / 2;
let scale = {
  x: scaleLinear().range([halfWidth - halfSquare, halfWidth + halfSquare]),
  y: scaleLinear().range([halfHeight + halfSquare, halfHeight - halfSquare]),
};

// Camera
let camera = {
  inclination: HALF_PI, // -0.5, 0, 0.5
  azimuth: 0, // Range -1, 0, 1
  center: {
    x: 0,
    y: 0,
    z: 0,
  },
};

// Create svg
let svg = select('.main')
  .append('div')
  .attr('class', 'background')
  .append('svg')
  .attr('viewBox', `0 0 ${options.width} ${options.height}`);

// Update data
const updateData = (svg, camera, scale, data) => {
  data.surfaces = processData(data.surfaces, camera);
  drawData(svg, scale, data.surfaces);
};

const processData = (surfaces, camera) => {
  return projectOrthographic(surfaces, camera);
};

const translatePoint = (point, center) => {
  return {
    x: point.x - center.x,
    y: point.y - center.y,
    z: point.z - center.z,
  };
};

const rotatePoint = (point, camera) => {
  let { x, y, z } = point;
  let { azimuth, inclination } = camera;
  // first rotate around y-axis to the azimuth angle
  let xp2 = x * Math.cos(azimuth) - z * Math.sin(azimuth);
  let zp2 = x * Math.sin(azimuth) + z * Math.cos(azimuth);

  // then around the x axis to pi/2 minus the inclination angle
  let angle = Math.PI / 2 - inclination;
  let yp3 = zp2 * Math.sin(angle) + y * Math.cos(angle);
  let zp3 = zp2 * Math.cos(angle) - y * Math.sin(angle);

  return {
    x: xp2,
    y: yp3,
    z: zp3,
  };
};

const ccw = polygon => {
  let _p = polygon.slice(0),
    sum = 0;
  _p.push(_p[0]);

  for (let i = 0; i <= polygon.length - 1; i++) {
    let j = i + 1;
    let p1 = _p[i];
    let p2 = _p[j];
    sum += (p2.px - p1.px) * (p2.py + p1.py);
  }
  // if the area is positive
  // the curve is counter-clockwise
  // because of the flipped y-Axis in the browser
  return sum > 0 ? true : false;
};

const centroid = polygon => {
  let _x = 0,
    _y = 0,
    _z = 0,
    _n = polygon.length;

  for (let i = _n - 1; i >= 0; i--) {
    _x += polygon[i].px;
    _y += polygon[i].py;
    _z += polygon[i].pz;
  }
  return {
    x: _x / _n,
    y: _y / _n,
    z: _z / _n,
  };
};

const projectOrthographic = (surfaces, camera) => {
  surfaces.forEach(points => {
    points.forEach(point => {
      let pointTranslated = translatePoint(point, camera.center);
      let pointRotated = rotatePoint(pointTranslated, camera);
      point.px = pointRotated.x;
      point.py = pointRotated.y;
      point.pz = pointRotated.z;
    });
    // Centroid & CCW
    points.ccw = ccw(points);
    points.centroid = centroid(points);
  });
  return surfaces;
};

const drawData = (svg, scale, surfaces) => {
  let polygons = svg.selectAll('path').data(surfaces);

  polygons
    .enter()
    .append('path')
    .merge(polygons)
    .attr('stroke-width', '0.5')
    .attr('stroke', d => {
      return d.ccw ? 'none' : '#fff';
    })
    .attr('fill', fill)
    .sort((a, b) => {
      let _a = a.centroid.z,
        _b = b.centroid.z;
      return _a < _b ? -1 : _a > _b ? 1 : _a >= _b ? 0 : NaN;
    })
    .attr('d', datum => {
      let d = datum.map(point => {
        return [scale.x(point.px), scale.y(point.py)];
      });
      return `M${d.join('L')}Z`;
    });
};

const azimuthScale = scaleLinear();
const inclinationScale = scaleLinear();
let myReq;
const animate = (azimuth, inclination, data) => {
  cancelAnimationFrame(myReq);
  let startValue = 0;
  let endValue = 1;
  let currentValue = startValue;

  azimuthScale.range([camera.azimuth, azimuth]);
  inclinationScale.range([camera.inclination, inclination]);

  function easeOut(value) {
    return -Math.pow(value - 1, 2) + 1;
  }

  function step() {
    currentValue += 0.1;
    if (currentValue < endValue) {
      camera.azimuth = azimuthScale(easeOut(currentValue));
      camera.inclination = inclinationScale(easeOut(currentValue));
      myReq = requestAnimationFrame(step);
    } else {
      camera.azimuth = azimuth;
      camera.inclination = inclination;
    }
    updateData(svg, camera, scale, data);
  }

  step();
};

json('mesh.json').then(data => {
  // Update camera
  // camera.azimuth = options.azimuth;
  camera.center = data.center;
  camera.center.x += options.x;
  camera.center.y += options.y;
  // Update scales
  scale.x.domain([-data.extreme, data.extreme]);
  scale.y.domain([-data.extreme, data.extreme]);
  // Process and Draw data
  updateData(svg, camera, scale, data);

  // Add animations
  function move(azimuth, inclination) {
    return () => animate(azimuth, inclination, data);
  }

  select('.background').style('opacity', 0).transition().duration(750).style('opacity', 1);

  timeout(move(-0.5, HALF_PI + 0.16), 1250);
  timeout(move(0, HALF_PI), 2250);

  timeout(move(0.5, HALF_PI - 0.16), 4250);
  timeout(move(0, HALF_PI), 5250);

  if (!touchDevice) {
    select('.heading')
      .on('mouseenter', move(0.5, HALF_PI - 0.16))
      .on('mouseleave', move(0, HALF_PI));

    select('.social')
      .on('mouseenter', move(-0.5, HALF_PI + 0.16))
      .on('mouseleave', move(0, HALF_PI));
  }

  // Create favicon
  favicon();
});
