function getArcPath(cx, cy, rx, ry, startAngle) {
  var theta = (startAngle * Math.PI) / 180;
  var dx = rx * Math.cos(theta);
  var dy = ry * Math.sin(theta);
  return [
    'M',
    cx + dx,
    cy + dy,
    'a',
    rx,
    ry,
    '0 1 1',
    2 * -dx,
    2 * -dy,
    'a',
    rx,
    ry,
    '0 1 1',
    2 * dx,
    2 * dy,
    'z',
  ].join(' ');
}

const faviconLink = document.querySelector('link[rel=icon]');
const canvas = document.createElement('canvas');
canvas.height = 64;
canvas.width = 64;

const favicon = letter => {
  let ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = '#fb4940';
  let shape = new Path2D(
    'M19.2021103,0 C8.59707761,0 0,8.56491625 0,19.2021103 L0,44.7978897 C0,55.4029224 8.56491625,64 19.2021103,64 L44.7978897,64 C55.4029224,64 64,55.4350837 64,44.7978897 L64,19.2021103 C64,8.59707761 55.4350837,0 44.7978897,0 L19.2021103,0 Z'
  );
  ctx.fill(shape);
  ctx.fillStyle = '#ffffff';
  let icon = new Path2D('M16 16 32 26.6666667 48 16 48 48 16 48');
  if (letter === 'o') {
    icon = new Path2D(getArcPath(32, 32, 16, 16, 90));
  } else if (letter === 't') {
    icon = new Path2D(
      'M16 16 48 16 48 26.6666667 37.3333333 26.6666667 37.2710938 48 26.6757812 48 26.6757812 26.6666667 16 26.6666667'
    );
  } else if (letter === 'a') {
    icon = new Path2D('M31.6666667 16 50.3333333 48 13 48');
  }
  ctx.fill(icon);
  faviconLink.href = canvas.toDataURL();
};

export default favicon;
