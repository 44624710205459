import { rgb } from 'd3-color';

let lightPoint = {
  x: 0,
  y: -1,
  z: 5,
};
let intensity = 0.3;

// Vectors
const distance = (v1, v2) => {
  let v3 = sub(v1, v2);
  return Math.sqrt(dot(v3, v3));
};

const normal = v => {
  let factor = 1 / Math.sqrt(dot(v, v));
  return {
    x: v.x * factor,
    y: v.y * factor,
    z: v.z * factor,
  };
};

const dot = (a, b) => {
  return a.x * b.x + a.y * b.y + a.z * b.z;
};

const sub = (a, b) => {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
    z: a.z - b.z,
  };
};

const fill = d => {
  if (d.ccw) return 'none';
  let normalCentroid = normal(d.centroid);
  let distancBetweenVectors = distance(lightPoint, d.centroid);
  let subBetweenVectors = sub(lightPoint, d.centroid);
  let lightNormal = normal(subBetweenVectors);
  let ddot = dot(lightNormal, normalCentroid);
  if (ddot < 0) {
    return rgb('#fb4940').brighter(distancBetweenVectors * intensity * ddot);
  }
  return '#fb4940';
};
export default fill;
